import React, { useEffect, useState } from "react";
import { X } from "lucide-react";

export default function CollapsibleAlert({ message }: { message: string }) {
  const [isVisible, setIsVisible] = useState(true);
  const alertId = `alert-${Buffer.from(message).toString("base64")}`;

  useEffect(() => {
    const lastDismissed = localStorage.getItem(alertId);
    if (lastDismissed) {
      const dismissedTime = parseInt(lastDismissed);
      const twentyFourHoursAgo = Date.now() - 24 * 60 * 60 * 1000;
      setIsVisible(dismissedTime < twentyFourHoursAgo);
    }
  }, [alertId]);

  const handleDismiss = () => {
    setIsVisible(false);
    localStorage.setItem(alertId, Date.now().toString());
  };

  if (!isVisible) return null;

  return (
    <div className="px-2 py-1 text-sm border rounded-md bg-red-50 text-red-600 border-red-400 flex justify-between items-center">
      <span>{message}</span>
      <button onClick={handleDismiss} className="ml-2 p-1 hover:bg-red-100 rounded-full" aria-label="Dismiss alert">
        <X size={14} />
      </button>
    </div>
  );
}
