"use client";
import AdminStoreSidebar from "./sidebar";
import { useStoreState } from "src/hooks/storeHooks";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import FullScreenLoader from "src/components/FullScreenLoader";
import { useUser } from "src/hooks/useUser";
import consts from "@/config/consts";
import useOrders from "@/hooks/useOrders";
import AccountNotifications from "./AccountNotifications";

export default function AdminStoreLayout({ children }: { children: React.ReactNode }) {
  const profile = useStoreState((state) => state.profile);

  const pathName = usePathname();
  const router = useRouter();

  const adminTenant = useStoreState((action) => action.adminTenant);

  /**
   * Check if user exists, fetch profile
   */
  const { isLoading: isUserAuthLoading } = useUser();
  const { orders, ordersCount, loading } = useOrders({
    search: "",
    page: 1,
    limit: consts.ORDERS_PER_PAGE,
    count: "exact",
    adminTenantId: adminTenant?.tenants?.id,
  });

  /**
   * Check if we can display the content of the page or a loading status
   */
  useEffect(() => {
    if (!profile && !isUserAuthLoading) router.push("/login");
    //if (!profile?.tenants || profile?.tenants.length == 0) router.push("/login/no-tenants");
  }, [profile, isUserAuthLoading, pathName, router]);

  return (
    <>
      {isUserAuthLoading && !profile && <FullScreenLoader label="Loading session..." />}
      {profile && (
        <AdminStoreSidebar ordersCount={ordersCount}>
          {/*<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 admin-store">{children}</div>*/}
          {/*<main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8"><div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 admin-store">{children}</div></div>
          </main>*/}
          <AccountNotifications />
          <div className="py-10 px-4 sm:px-6 lg:px-8 admin-store">{children}</div>
        </AdminStoreSidebar>
      )}
    </>
  );
}
