import React, { useEffect, useState } from "react";
import { useStoreState } from "src/hooks/storeHooks";
import CollapsibleAlert from "../../../components/ui/collapsible-alert";
import { stripeStatus } from "../../../helpers/stripeStatus";
export default function AccountNotifications() {
  const tenant = useStoreState((state) => state.adminTenant);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (!tenant?.tenants?.studio_domain) {
      setErrors([
        "Your domain name has not been configured. Please contact support@getflexchain.com to get it set up.",
      ]);
    }
  }, [tenant]);

  return (
    <>
      {errors && (
        <div className="flex flex-col gap-1 p-3">
          {errors.map((error: string, index: number) => (
            <CollapsibleAlert message={error} key={index} />
          ))}
        </div>
      )}
    </>
  );
}
