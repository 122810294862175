import React from "react";
import { useStoreState } from "@/hooks/storeHooks";
import Image from "next/image";
interface RestrictedProps {
  roleName: string;
  children: React.ReactNode;
  invisible?: boolean;
}

export const PresetRoles = {
  "read-only": {
    title: "Read-only",
    description: "Can read everything (not settings).",
  },
  basic: {
    title: "Basic",
    description: "Can interact with everything except settings.",
  },
  owner: {
    title: "Owner",
    description: "Can do anything.",
  },
};

export const PlansConfig = [
  {
    name: "Basic",
    slogan: "You're just starting out with online orders",
    monthlyPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R31jdAIUBjQ7ZysaOpE0DSf" : "price_1R5vy7POQgr1Uz60E2Jvc3yb",
    annualPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R31kIAIUBjQ7ZysrzKUFqDw" : "price_1R5vyZPOQgr1Uz60y9yBtyWf",
    shownInTable: true,
    priceMonthly: 24.9,
    priceAnnual: 249.0,
    features: [
      "Publish up to 15 products.",
      "1 Staff account.",
      "Up to 2 embelishment techniques.",
      "Unlimited orders.",
      "Only 1.8% transaction fee (+ card fees).",
    ],
    applicationFee: 0.018,
  },
  {
    name: "Growth",
    slogan: "All your catalog is available online.",
    monthlyPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R31jxAIUBjQ7ZysaUtedlI1" : "price_1R6aWsPOQgr1Uz60ZIOv4d8D",
    annualPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R31kdAIUBjQ7ZysGXtqan1S" : "price_1R6aX7POQgr1Uz60psc4WXK7",
    shownInTable: true,
    priceMonthly: 54.9,
    priceAnnual: 549,
    features: [
      "Publish up to 75 products.",
      "Up to 3 staff accounts.",
      "Up to 3 embelishment techniques.",
      "Unlimited orders.",
      "Only 1.6% transaction fee (+ card fees).",
      "Support rush orders.",
    ],
    applicationFee: 0.016,
  },
  {
    name: "Pro",
    slogan: "Your entire team uses Flexchain on auto-pilot.",
    monthlyPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R31l0AIUBjQ7Zyse5rUJd2Z" : "price_1R6aXNPOQgr1Uz602yFzMTfC",
    annualPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R31lJAIUBjQ7ZysnyIyPGLI" : "price_1R6aXjPOQgr1Uz60nfOLrTE4",
    shownInTable: true,
    priceMonthly: 199,
    priceAnnual: 1990,
    features: [
      "Unlimited products.",
      "Unlimited staff accounts.",
      "Unlimited embelishment techniques.",
      "Unlimited orders.",
      "Only 1.4% transaction fee (+ card fees).",
      "Support rush orders.",
      "Unlimited blanks templates.",
      'Remove "Powered by Flexchain" in the footer.',
      "White-labelled Print on Demand with your customers.",
    ],
    applicationFee: 0.014,
  },
  {
    name: "dev_pablo",
    slogan: "10% application fee",
    priceMonthly: 1,
    priceAnnual: 1,
    features: ["Test notifications in prod"],
    shownInTable: false,
    monthlyPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R6djZAIUBjQ7ZysSz1aOMaw" : "price_1R6d7JPOQgr1Uz60JTDYskrZ",
    annualPriceId:
      process.env.NEXT_PUBLIC_ENV == "production" ? "price_1R6djmAIUBjQ7ZysfQSKIhgi" : "price_1R6d8sPOQgr1Uz60i2CMbOkS",
    applicationFee: 0.1,
  },
];

export default function Restricted({ roleName, children, invisible = true }: RestrictedProps) {
  const tenant = useStoreState((s) => s.adminTenant);

  if (hasPermission(roleName, tenant)) {
    return <>{children}</>;
  }

  if (invisible) {
    return <></>;
  }

  return (
    <div className="flex flex-col space-y-4">
      <Image src={"/undraw-illustrations/restricted.svg"} alt="Access denied" width={200} height={200} />
      <div className="max-w-md">
        <p className="font-bold text-lg">Access denied</p>
        <p className="text-muted-foreground">
          {"You don't have the permissions to access this page. Ask your shop admin to give you this permission."}
        </p>
      </div>
    </div>
  );
}

export function hasPermission(roleName: string, tenant?: any) {
  return tenant?.roles?.includes(roleName) || tenant?.roles?.includes("*") || tenant?.roles?.includes("owner");
}
